//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import Password from '~/components/form/Password.vue'
import CodeInput from '~/components/form/CodeInput.vue'
export default {
  components: { Password, CodeInput },
  layout: 'auth',
  props: {
    refreshAfterLogin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      signature: '',
      step: 1,
      pending: '',
      afterLink: 'hello',
      password: '',
      email: '',
      fullName: '',
      code: '',
      recaptcha: false,
      reset: false,
      timer: 0,
      backErrors: {
        email: false,
        code: false,
      },
    }
  },
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(64),
    },
    password: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(32),
    },
    fullName: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(64),
    },
    code: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(6),
    },
  },
  watch: {
    code(newValue, oldValue) {
      this.backErrors.code = false
    },

    step(val) {
      if (this.step === 2) {
        this.timer = 60
        this.countDownTimer()
      }
    },
  },

  mounted() {
    this.timer = 60
    this.countDownTimer()
  },
  methods: {
    async submit() {
      if (this.pending) return
      this.pending = true

      this.$v.fullName.$touch()
      this.$v.email.$touch()
      this.$v.password.$touch()
      // this.$auth.strategy.token.reset();
      this.$auth.setUserToken(undefined, undefined)

      if (
        !this.$v.fullName.$anyError &&
        !this.$v.email.$anyError &&
        !this.$v.password.$anyError
      ) {
        try {
          await this.$axios
            .$post(
              '/auth/registration/',
              {
                full_name: this.fullName,
                email: this.email,
                password: this.password,
              },
              {
                headers: { 'Accept-Language': this.$i18n.locale },
              }
            )
            .then((response) => {
              this.signature = response.signature
              this.$auth.setUserToken(undefined, undefined)
              this.step = 2
              this.$toast.success(
                `${this.$t('code_mailed')} ${this.email} ${this.$t(
                  'code_mailed_continued_text'
                )}`
              )
            })
            .catch(() => {})
        } catch (err) {
          // this.backErrors.email = true;
        }
      }
      this.pending = false
    },
    async submitSecondStage() {
      if (this.pending) return
      this.pending = true
      this.$v.code.$touch()
      if (!this.$v.code.$anyError) {
        await this.$axios
          .$post('auth/verify-email/', {
            code: this.code,
            signature: this.signature,
          })
          .then((response) => {
            if (this.refreshAfterLogin) {
              window.location.reload()
            }
            if (response.access) {
              this.$auth.setUserToken(response.access, response.refresh)
              this.$auth.refreshTokens()
              this.$auth.fetchUser()
              this.$store.dispatch('profile/fetchProfile')
              setTimeout(() => {
                if (process.client) {
                  if (localStorage.getItem('current_path')) {
                    this.$router.push(localStorage.getItem('current_path'))
                  } else {
                    this.$router.push('/')
                  }
                }
              }, 200)
            } else {
              this.$auth.setUserToken(undefined, undefined)
              this.$router.push('/auth/register')
            }
          })
          .catch(() => {
            // this.$toast.error(this.$t('code_incorrectly'));
            this.backErrors.code = true
          })
      }
      this.pending = false
    },
    isDisabled() {
      if (!this.recaptcha && this.$v.$anyDirty) {
        return true
      } else if (this.recaptcha) {
        return false
      } else {
        return true
      }
    },
    onChange(v) {
      this.code = v
    },
    onComplete(v) {
      this.code = v
      if (v.length === 6) {
        this.submitSecondStage()
      }
    },
    resend() {
      this.timer = 60
      this.submit()
      this.countDownTimer()
    },
    countDownTimer() {
      if (this.timer > 1) {
        setTimeout(() => {
          this.timer -= 1
          this.countDownTimer()
        }, 1000)
      }
    },
  },
}
