//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BeforeAuthLogin from './BeforeAuth.vue'
export default {
  components: { BeforeAuthLogin },
  props: {
    showingLinks: {
      type: Boolean,
      default: false,
    },
    nudgeTop: {
      type: Number,
      default: 200,
    },
    nudgeLeft: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      dialog: false,
      random: 1,
    }
  },
  methods: {
    openModal() {
      this.random = Math.random()
      this.dialog = true
      this.$emit('falsifyShowingLinks')
    },
  },
}
