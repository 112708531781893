//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Register from '@/components/page/Register.vue'
import Reset from '@/components/page/Reset.vue'
import Login from '@/components/page/Login.vue'
// import Telegram from '@/pages/auth/telegram.vue'
export default {
  components: { Login, Reset, Register },
  layout: 'auth',
  id: 'special',
  data() {
    return {
      google: undefined,
      loginStep: false,
      registerStep: false,
      resetStep: false,
      authStep: true,
    }
  },
  mounted() {
    if (this.$route.query.message) {
      const message = this.$route.query.message
      if (message === 'login_first') {
        this.$toast.warning(this.$t('please_login_first'))
      }
    }
  },
  methods: {
    async loginWithGoogle() {
      try {
        await this.$auth.loginWith('google')
      } catch (err) {}
    },
  },
}
