//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import Password from '~/components/form/Password.vue'
export default {
  name: 'Login',
  components: {
    Password,
  },
  props: {
    refreshAfterLogin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      password: '',
      pending: false,
      email: '',
      backErrors: {
        email: false,
      },
    }
  },
  validations: {
    email: {
      required,
      email,
      maxLength: maxLength(64),
    },
    password: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(32),
    },
  },
  watch: {
    email(newValue, oldValue) {
      this.backErrors.email = false
    },
    password(newValue, oldValue) {
      this.backErrors.email = false
    },
  },
  methods: {
    async submit() {
      if (this.pending) return
      this.pending = true
      this.$v.$touch()
      if (!this.$v.$anyError) {
        try {
          await this.$auth
            .loginWith('local', {
              data: { email: this.email, password: this.password },
            })
            .then(() => {
              this.$auth.fetchUser()
              if (this.refreshAfterLogin) {
                window.location.reload()
              }
              // this.$router.push('/');
              if (process.client) {
                if (localStorage.getItem('current_path')) {
                  this.$router.push(localStorage.getItem('current_path'))
                } else {
                  this.$router.push('/')
                }
              }
            })
            .catch(() => {
              console.clear()
              this.$toast.error(
                this.$t('auth_login.mail_or_password_entered_incorrectly')
              )
            })
        } catch (error) {
          this.backErrors.email = true
        }
      }
      this.pending = false
    },
  },
}
